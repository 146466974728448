import apiClient from '@/services/axios'
export const generateTask = async payload => {
  const res = await apiClient.post(`/eos/generate`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getTaskByDate = async payload => {
  const res = await apiClient.get(`/eos/by-date`, payload)
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getTaskByDetail = async id => {
  const res = await apiClient.get(`/eos/by-detail`, { params: { mtMasterId: id } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const postAnswers = async answers => {
  const res = await apiClient.post(`/eos/answer`, { answers })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const checkAssets = async userId => {
  const res = await apiClient.get(`/eos/check`, { params: { userId } })
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const createChangeRequestEos = async payload => {
  const res = await apiClient.post('/eos/request', payload)
  if (res) {
    return res.data
  } else {
    return null
  }
}
